import React, { PropsWithChildren } from 'react';
import { FeatureFlagged } from '@backstage/core-app-api';
import { SidebarDivider } from '@backstage/core-components';
import { Root as DefaultRoot } from '@platt-internal/backstage-plugin-bootstrap-react';
import { RepositoryCatalogSidebarItems } from '@platt/backstage-plugin-bootstrap-repository-catalog-react';
import { FaqsSidebarItems, MinuteGraphSidebarItems } from '@platt/backstage-plugin-bootstrap-faqs-react';
import { OnboardingDemoSidebarItems } from '@platt/backstage-plugin-onboarding-demo';

const additionalMenuItems = (
  <>
    <FaqsSidebarItems />
    <FeatureFlagged with="minute-graph">
      <MinuteGraphSidebarItems />
    </FeatureFlagged>
    <FeatureFlagged with="repository-catalog">
      <RepositoryCatalogSidebarItems />
    </FeatureFlagged>
    <SidebarDivider />
    <OnboardingDemoSidebarItems />
  </>
);

export const Root = ({ children }: PropsWithChildren<{}>) => {

  return (
    <DefaultRoot additionalMenuItems={additionalMenuItems}>
      {children}
    </DefaultRoot>
  );
};
