import { createApiRef } from '@backstage/core-plugin-api';
import { AskMeetingResponse, AskRequest, AskResponse } from '@platt/plugin-platt-cognitive-faq-common';

export const cognitiveFaqRef = createApiRef<CognitiveFaqApi>({
  id: 'platt.cognitive.faq.service'
});
/**
 * askの結果を呼出基で判定後listenする想定。
 */
export interface CognitiveFaqApi {
  ask(request: AskRequest): Promise<AskResponse>;
  askMeeting(request: AskRequest): Promise<AskMeetingResponse>;
  askTemplate(request: AskRequest): Promise<AskResponse>;
  content(query: string): Promise<void>;
}