import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, fetchApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { CognitiveFaqClient } from './api';
import { cognitiveFaqRef } from './api/CognitiveFaqApi';

export const plattCognitiveFaqPlugin = createPlugin({
  id: 'platt-cognitive-faq',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: cognitiveFaqRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new CognitiveFaqClient({
          discoveryApi,
          fetchApi,
        }),
    })
  ],
});

export const PlattCognitiveFaqPage = plattCognitiveFaqPlugin.provide(
  createRoutableExtension({
    name: 'PlattCognitiveFaqPage',
    component: () =>
      import('./components/FaqPage').then(m => m.FaqPage),
    mountPoint: rootRouteRef,
  }),
);
