import { Config } from '@backstage/config';

// config for frondend
export interface CognitiveMinuteGraphConfig {
  enabled?: boolean;
}

export const isCognitiveMinuteGraphnEnabled = (config: Config) => {
  const meetingGraphConfig = config.getOptionalConfig('platt.cognitiveMinuteGraph');
  return meetingGraphConfig?.getOptionalBoolean('enabled') ?? false;
}
