import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { MeetingEntityV1alpha1, meetingEntityV1alphaValidator } from '@platt/plugin-platt-cognitive-minutes-common';
import { useCallback, useState } from 'react';


export function useMeeting() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [meetings, setMeetings] = useState<MeetingEntityV1alpha1[]>([]);
  const api = useApi(catalogApiRef);
  const getMeetings = useCallback(async (entity: Entity) => {
    const es = await api.getEntities({ filter: { kind: 'Meeting', 'spec.repoRef': stringifyEntityRef(entity) } });
    const ms = es.items.filter(e => meetingEntityV1alphaValidator.check(e)) as MeetingEntityV1alpha1[];
    setIsLoaded(true);
    setMeetings(ms);
  }, [api],);

  return { isLoaded, meetings, getMeetings };
}
