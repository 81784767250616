import {
  createRouteRef,
  createSubRouteRef
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'platt-repository-catalog',
});

export const issueRouteRef = createSubRouteRef({
  id: 'platt-repository-catalog/issue',
  path: '/issues/:id',
  parent: rootRouteRef,
});

import { createExternalRouteRef } from '@backstage/core-plugin-api';

export const createComponentRouteRef = createExternalRouteRef({
  id: 'create-component',
  optional: true,
});

export const catalogIndex = createExternalRouteRef({
  id: 'catalog-index',
  optional: true,
});
