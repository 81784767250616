import { useCallback, useState } from 'react';

export function useUploadModal(initialState = false) {
  const [state, setState] = useState({
    hidden: !initialState,
    open: initialState,
  });

  const toggleModal = useCallback(
    () =>
      setState(prevState => ({
        open: true,
        hidden: !prevState.hidden,
      })),
    [],
  );

  const setOpen = useCallback(
    (open: boolean) =>
      setState(prevState => ({
        open: prevState.open || open,
        hidden: !open,
      })),
    [],
  );

  // Inherit from parent context, if set.
  return { state, toggleModal, setOpen };
}
