import React from 'react';
import { Route } from 'react-router-dom';
import { OnboardingDemoPage } from './plugin';

export const onboardingDemoRoutes = () => {
  return (
    <>
      <Route path="/onboarding" element={<OnboardingDemoPage />} />
    </>
  );
};
