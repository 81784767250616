import { createExternalRouteRef, createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'platt-cognitive-minutes',
});

export const catalogRouteRef = createSubRouteRef({
  id: 'catalog',
  parent: rootRouteRef,
  path: '/catalog',
});

export const registerComponentRouteRef = createExternalRouteRef({
  id: 'register-component',
  optional: true,
});