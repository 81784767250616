import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'platt-cognitive-minute-graph',
});

export const catalogRouteRef = createSubRouteRef({
  id: 'meeting',
  parent: rootRouteRef,
  path: '/meeting',
});