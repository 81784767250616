import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const onboardingDemoPlugin = createPlugin({
  id: 'onboarding-demo',
  routes: {
    root: rootRouteRef,
  },
});

export const OnboardingDemoPage = onboardingDemoPlugin.provide(
  createRoutableExtension({
    name: 'OnboardingDemoPage',
    component: () =>
      import('./components/OnboardingDemo').then(m => m.OnboardingDemo),
    mountPoint: rootRouteRef,
  }),
);
