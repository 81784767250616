
import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Meeting.v1alpha1.schema.json';

/**
 * Backstage catalog Meeting kind Entity.Meeting are used by the Scaffolder
 * plugin to create new entities, such as Components.
 *
 * @public
 */
export interface MeetingEntityV1alpha1 extends Entity {
  /**
   * The apiVersion string of the TaskSpec.
   */
  apiVersion: 'platt.ap-com.app/v1alpha1';
  /**
   * The kind of the entity
   */
  kind: 'Meeting';

  /**
   * The specification of the Minute Entity
   */
  spec: {
    title: string;
    meetingId: string;
    type: string;
    owner?: string;
    repoRef: string;
    path: string;
    tickets?: string[];
    paticipants?: string[];
    heldAt: string;
    lifecycle?: string;
    updateAt?: string;
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link PlattLibraryEntityV1alpha1}.
 *
 * @public
 */
export const meetingEntityV1alphaValidator: KindValidator = {
  // TODO(freben): Emulate the old KindValidator until we fix that type
  async check(data: Entity) {
    return validator(data) === data;
  },
};

/**
 * Typeguard for filtering entities and ensuring v1beta3 entities
 * @public
 */
export const isMeetingEntityV1alpha = (
  entity: Entity,
): entity is MeetingEntityV1alpha1 =>
  entity.apiVersion === 'platt.ap-com.app/v1alpha1' &&
  entity.kind === 'Meeting';
