import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarItem } from '@backstage/core-components';
import { isRepositoryCatalogEnabled } from '@platt/plugin-repository-catalog-common';
import FlareIcon from '@material-ui/icons/Flare';

export const RepositoryCatalogSidebarItems = () => {
  const config = useApi(configApiRef);

  return (
    <>
      { isRepositoryCatalogEnabled(config) &&
        <SidebarItem icon={FlareIcon} to="platt-repository-catalog" text="Repository Graph" />
      }
    </>
  );
};
