import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  QueryIssueGraphResponse,
  RepositoryIsuesResponse
} from '@platt/plugin-repository-catalog-common';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { ResponseError } from '@backstage/errors';
import { QueryPageinationOptions, RepositoryApi } from './RepositoryApi';

interface CallParams {
  path: string;
  method?: 'GET' | 'POST';
  query?: { [key: string]: any }
}

export interface RepisotiryClientOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
}

export class RepisotiryClient implements RepositoryApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: RepisotiryClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  getRepositoryIssues(
    entityRef: CompoundEntityRef,
    options?: QueryPageinationOptions
  ): Promise<RepositoryIsuesResponse> {
    const { kind, namespace, name } = entityRef;

    const hasQueryParams = options?.page !== undefined && options?.pageSize !== undefined;
    const param: CallParams = {
      path: `repository/${kind}/${namespace}/${name}/issues`,
      query:  hasQueryParams ? {
        page: options?.page !== undefined ? options.page + 1 : undefined,
        pageSize: options?.pageSize
      } : undefined,
    }
    return this.callApi<RepositoryIsuesResponse>(param);
  }

  retrieveIssuesFromRepository(entityRef: CompoundEntityRef): Promise<void> {
    const { kind, namespace, name } = entityRef;
    const param: CallParams = {
      path: `repository/${kind}/${namespace}/${name}/issues/retrieve`,
      method: 'POST',
    }
    return this.callApi<void>(param);
  }

  getIssueGraphById(id: string): Promise<QueryIssueGraphResponse> {
    const param: CallParams = {
      path: `issues/${id}/graph`,
    }
    return this.callApi<QueryIssueGraphResponse>(param);
  }

  private async callApi<T>(params: CallParams): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-repository-catalog')}/`;
    const url = new URL(params.path, baseUrl);
    const query = new URLSearchParams(params.query);

    const path = `${url.toString()}${(params.query ? `?${query.toString()}` : '')}`;
    const response = await this.fetchApi.fetch(path, {
      method: params.method ?? 'GET',
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}
