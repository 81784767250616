import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, Grid, IconButton, ListItem, ListItemIcon, ListItemText, TextField, Typography, makeStyles } from '@material-ui/core';
import { MediaDropZone } from './DropZone';
import { useBlobStorage } from '../../hooks/useBlobStorage';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import { useEntity } from '@backstage/plugin-catalog-react';
import { MeetingEntityV1alpha1 } from '@platt/plugin-platt-cognitive-minutes-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  Content,
} from '@backstage/core-components';
import { DateAndTimePicker } from '../common/DateAndTimePicker';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    gap: theme.spacing(1),
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    '&> button': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    flex: 1,
  },
  button: {
    '&:hover': {
      background: 'none',
    },
  },
  // Reduces default height of the modal, keeping a gap of 128px between the top and bottom of the page.
  paperFullWidth: { height: '580px', width: '580px' },
  dialogActionsContainer: { padding: theme.spacing(1, 3) },
  viewResultsLink: { verticalAlign: '0.5em' },
}));
/**
 * @public
 */
export interface MeetingUploadPageProps {
  open?: boolean;
  hidden?: boolean;
  toggleModal?: () => void;
  meetings: MeetingEntityV1alpha1[];
}

export const MeetingUploadDialog = (props: MeetingUploadPageProps) => {
  const { open = false, hidden = true, toggleModal, meetings: preMeetings } = props;
  const [meetings, setMeetings] = useState<MeetingEntityV1alpha1[]>(preMeetings || []);
  const classes = useStyles();
  const [title, setTitle] = useState<string>('');
  const [heldAt, setHeldAt] = useState<Date>(new Date());
  const [description, setDescription] = useState<string>('');
  const { upload, uploading, uploadProgress } = useBlobStorage();
  const [files, setFiles] = useState<File[]>([]);
  const libraryEntity = useEntity<MeetingEntityV1alpha1>();
  const onDrop = async (f: File[]) => {
    // 指定外のファイルがあれば、何も処理をしない
    setTitle(f[0].name.replace('.mp4', ''));
    const fileDate = new Date(f[0].lastModified);
    // mui v3の間の暫定対応、別のpickerやv5に移行した際に変更する。
    fileDate.setHours(fileDate.getHours() + 9, 0, 0, 0);
    setHeldAt(fileDate);
    setFiles(f);
  };
  useEffect(() => {
    setMeetings(preMeetings);
  }, [preMeetings]);

  const onSubmit = async () => {
    if (files && files.length > 0) {
      await upload({
        files: files,
        gitUrl: libraryEntity.entity.metadata.annotations!['backstage.io/view-url'],
        ref: stringifyEntityRef(libraryEntity.entity),
        title,
        dateTime: heldAt.toISOString(),
        description,
      });
      setFiles([]);
      setTitle('');
      setDescription('');
      toggleModal?.();
      window.location.reload()
    }
  };

  const inputTitleRef = useRef<HTMLInputElement>(null);
  const [inputTitleError, setInputTitleError] = useState(false);
  const handleTitleChange = () => {
    if (inputTitleRef.current) {
      const ref = inputTitleRef.current;
      if (!ref.validity.valid || meetings.find((m) => m.metadata.name === ref.value)) {
        setInputTitleError(true);
      } else {
        setInputTitleError(false);
      }
    }
  };

  const onDateTimeChange = (date: Date) => {
    setHeldAt(date);
  }

  const inputDescriptionRef = useRef<HTMLInputElement>(null);
  const [inputDescriptionError, setInputDescriptionError] = useState(false);
  const hanadleDescriptionChange = () => {
    if (inputDescriptionRef.current) {
      const ref = inputDescriptionRef.current;
      if (!ref.validity.valid) {
        setInputDescriptionError(true);
      } else {
        setInputDescriptionError(false);
      }
    }
  };

  return (
    <Dialog classes={{
      paperFullWidth: classes.paperFullWidth,
    }}
      onClose={uploading ? undefined : toggleModal}
      aria-labelledby="search-modal-title"
      fullWidth
      maxWidth="lg"
      open={open}
      hidden={hidden}>
      <Content>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6" gutterBottom>
                Upload Meeting Video (only mp4)
              </Typography>
              <IconButton edge="end" onClick={toggleModal}>
                <Close />
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <TextField fullWidth value={title}
                error={inputTitleError}
                id='title'
                label='title'
                name='title'
                lang='ja'
                type='type'
                inputRef={inputTitleRef}
                helperText={inputTitleRef?.current?.validationMessage || ''}
                onInput={handleTitleChange}
                inputProps={{
                  maxLength: 100,
                  style: { fontSize: 20 },
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(event.target.value);
                }}
                InputLabelProps={{ style: { fontSize: 18 }, shrink: true }} />
            </Box>
          </Grid>
          <Grid item>
            <DateAndTimePicker onChange={onDateTimeChange} value={heldAt} />
          </Grid>
          <Grid item>
            <Box>
              <TextField fullWidth value={description}
                id='description'
                label='description'
                name='description'
                inputRef={inputDescriptionRef}
                error={inputDescriptionError}
                onInput={hanadleDescriptionChange}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDescription(event.target.value);
                }}
                helperText={inputTitleRef?.current?.validationMessage || ''}
                inputProps={{ style: { fontSize: 20 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 18 }, shrink: true }} />
            </Box>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={200}
              width="100%"
              minWidth={300}
              borderColor="grey"
              paddingY={2}
              flexDirection='column'
            >
              {
                (files.length > 0 ?
                  (
                    <ListItem>
                      <ListItemText primary="video" secondary={files[0].name} />
                      <ListItemIcon>
                        <IconButton disabled={uploading} edge="end" aria-label="delete" onClick={() => setFiles([])}>
                          {uploading ? <CircularProgress /> : <Delete />}
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  ) : <MediaDropZone onDrop={onDrop} />)
              }
              <Typography variant='body1'>
                {uploading ? `${uploadProgress}%` : ' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button color="primary" variant='contained' disabled={!(files.length > 0 && title.length > 0) || uploading || inputTitleError} onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}>
              upload
            </Button>
          </Grid>
        </Grid >
      </Content>
    </Dialog>
  );
};
