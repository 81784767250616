import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { TranscriptApi } from './transcriptionApi';
import { Auth, AuthRequest, GetMeetingResponse,} from '@platt/plugin-platt-cognitive-minutes-common';

export interface TranscriptClientOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
}

export class TranscriptClient implements TranscriptApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: TranscriptClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  
  async getMeeting(id: string): Promise<GetMeetingResponse> {
    const abortController = new AbortController();
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-minutes')}/`;
      const result = await this.fetchApi.fetch(`${baseUrl}storage/meetings/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'connection': 'keep-alive'
        } as HeadersInit,
        signal: abortController.signal
      }).then(response => {
        if (!response.body) {
          throw new Error('response body null');
        }
        if (!response.ok) {
          throw new Error('response not ok');
        }
        return response.json();
      }) as GetMeetingResponse;
      return result;
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }

  async getAuth(request: AuthRequest): Promise<Auth> {
    const abortController = new AbortController();
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-minutes')}/`;
      return this.fetchApi.fetch(`${baseUrl}storage/auth`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'connection': 'keep-alive'
        } as HeadersInit,
        body: JSON.stringify(request),
        signal: abortController.signal
      }).then(response => {
        if (!response.body) {
          throw new Error('response body null');
        }
        if (!response.ok) {
          throw new Error('response not ok');
        }
        return response.json();
      });
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }
}