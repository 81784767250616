import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback, useEffect, useState } from 'react';
import { transcriptApiRef } from '../api/transcriptionApi';
import { BlobServiceClient } from '@azure/storage-blob';
import parseUrl from 'git-url-parse';
import React from 'react';
import { TransferProgressEvent } from '@azure/core-http';
import { Config } from '@backstage/config';

export interface MeetingUploadProps {
  files: File[];
  gitUrl: string;
  ref: string;
  title?: string;
  dateTime?: string;
  description?: string;
}

/**
 * configからContainerの名前を取得する関数。
 * 参照する議事データ項目が増える都度こことconfig.dを拡張する。
 * 
 * @param config 
 * @returns 
 */
function getContainerNameFromConfig(config: Config) {
  const azureBlobConfig = config.getConfig('platt.cognitiveMinute');
  return {
    video: azureBlobConfig.getString('azureStorageVideoContainerName'),
    meeting: azureBlobConfig.getString('azureStorageMeetingContainerName'),
  };
}


export function useBlobStorage() {
  // const entity = useEntity<MeetingRepoEntityV1alpha1>();
  const [uploading, setUpLoading] = useState(false);
  const [uploadProgress, setProgress] = useState<number>(0);
  const api = useApi(transcriptApiRef);
  const config = useApi(configApiRef);
  const containerNames = getContainerNameFromConfig(config);
  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const upload = useCallback(async (options: MeetingUploadProps) => {
    const { files, gitUrl, ref, title, dateTime, description } = options;
    setUpLoading(true);
    const { owner, name, filepath } = parseUrl(gitUrl);
    return await api.getAuth({
      owner: owner,
      repo: name,
      path: filepath,
      ref: ref,
      title: title,
      heldAt: dateTime,
      description: description
    }).then((res) => {
      // Promise.allを使ってすべてのファイルのアップロードを待つ
      return Promise.all(files.map(async (file) => {
        const blobServiceClient = new BlobServiceClient(res.sasUrl);
        // 拡張子
        const extention = file.name.split('.').pop();
        const blobName = `${res.calalogId}.${extention}`;

        // コンテナーとBlobのクライアントを作成
        const containerClient = blobServiceClient.getContainerClient(containerNames.video);
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);


        // FileオブジェクトのarrayBuffer()メソッドでArrayBufferオブジェクトを取得
        const arrayBuffer = await file.arrayBuffer();
        // Buffer.from()メソッドでBufferオブジェクトに変換
        const buffer = Buffer.from(arrayBuffer);

        const blob = new Blob([buffer], { type: file.type });

        // ブロックサイズと再試行ポリシーを設定
        const blockSize = 4 * 1024 * 1024; // 4MB

        const onProgress = (progress:TransferProgressEvent) => {
          setProgress(Math.round(progress.loadedBytes / file.size * 100));
        };
        // uploadStreamメソッドでファイルをアップロード
        await blockBlobClient.uploadData(blob, { blockSize: blockSize, onProgress: onProgress, tracingOptions: { }});
      })).catch((_) => {
        throw new Error('ファイルのアップロードに失敗しました。');
      }).finally(() => {
        setUpLoading(false);
      });
    }).catch((_)=>{
      throw new Error('アップロードの認証中に失敗しました。');
    }).finally(() => {
      setUpLoading(false);
    });
  }, [api, containerNames]);

  const getMeetingText = useCallback(async (id: string) => {
    const result = await api.getMeeting(id);
    const headeredText = result.text.replaceAll('- title:', '#').replaceAll('- theme:', '##').replaceAll('- subtopic:', '-').replaceAll('- topic:', '-');
    return { text: result.text, headeredText: headeredText, meeting: result.structuredMeeting }
  }, [api]);

  return {
    upload,
    getMeetingText,
    uploadProgress,
    uploading,
  };
}
