import React from 'react'
import { Grid } from '@material-ui/core';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityPage as DefaultEntityPage } from '@platt-internal/backstage-plugin-bootstrap-react';
import { RepositoryCatalogContent } from '@platt/plugin-repository-catalog';

export const repositoryCatalogPage = () => (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {DefaultEntityPage.minimumOverviewContent()}
    </EntityLayout.Route>

    <EntityLayout.Route path="/repoGraph" title="Repository Graph">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <RepositoryCatalogContent />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
