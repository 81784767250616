import { Config } from '@backstage/config';

// config for frondend
export interface CognitiveFaqConfig {
  enabled?: boolean;
}

export const isCognitiveFaqPluginEnabled = (config: Config) => {
  const faqConfig = config.getOptionalConfig('platt.cognitiveFaq');
  return faqConfig?.getOptionalBoolean('enabled') ?? false;
}
