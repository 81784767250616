import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';

import { registerComponentRouteRef, rootRouteRef } from './routes';
import { transcriptApiRef } from './api/transcriptionApi';
import { TranscriptClient } from './api/transcriptionApiClient';

export const plattCognitiveMinutesPlugin = createPlugin({
  id: 'platt-cognitive-minutes',
  apis: [
    createApiFactory({
      api: transcriptApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new TranscriptClient({
          discoveryApi,
          fetchApi, 
          
        }),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    registerComponent: registerComponentRouteRef,
  }
});

export const PlattCognitiveMinutesPage = plattCognitiveMinutesPlugin.provide(
  createRoutableExtension({
    name: 'Root',
    component: () =>
      import('./components/Root').then(m => m.Root),
    mountPoint: rootRouteRef,
  }),
);
