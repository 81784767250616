import React from 'react';
import { Route } from 'react-router-dom';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { AppRouteBinder } from '@backstage/core-app-api';
import { RepositoryCatalogPage, repositoryCatalogPlugin } from '@platt/plugin-repository-catalog';

export const repositoryCatalogAppRoutes = () => {
  return (
    <Route path="/platt-repository-catalog" element={<RepositoryCatalogPage />} />
  );
};

export const bindRepositoryCatalogRoutes = ({ bind }: { bind: AppRouteBinder } ) => {
  bind(repositoryCatalogPlugin.externalRoutes, {
    catalogIndex: catalogPlugin.routes.catalogIndex
  });
};
