import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { catalogIndex, createComponentRouteRef, rootRouteRef } from './routes';
import { RepisotiryClient, repositoryApiRef } from './api';

export const repositoryCatalogPlugin = createPlugin({
  id: 'repository-catalog',
  apis: [
    createApiFactory({
      api: repositoryApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef
      },
      factory: ({ discoveryApi, fetchApi }) => 
        new RepisotiryClient({
          discoveryApi,
          fetchApi
        }),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    catalogIndex: catalogIndex,
    createComponent: createComponentRouteRef,
  }
});

export const RepositoryCatalogPage = repositoryCatalogPlugin.provide(
  createRoutableExtension({
    name: 'RepositoryCatalogPage',
    component: () =>
      import('./components/CatalogPage').then(m => m.CatalogPaege),
    mountPoint: rootRouteRef,
  }),
);

export const RepositoryCatalogContent = repositoryCatalogPlugin.provide(
  createRoutableExtension({
    name: 'RepositoryCatalogContent',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef
  })
);
