
import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarItem } from '@backstage/core-components';
import AssesmentIcon from '@material-ui/icons/Assessment';
import { getSidebarLinkTitle, isOnboardingDemoEnabled } from '../../config';

export const OnboardingDemoSidebarItems = () => {
  const configApi = useApi(configApiRef);
  const title = getSidebarLinkTitle(configApi);

  return (
    <>
      {
        isOnboardingDemoEnabled(configApi) &&
          <SidebarItem icon={AssesmentIcon} to="onboarding" text={title} />
      }
    </>
  );
};
