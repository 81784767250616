import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((_) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
      sx: {
        svg: {
          color: 'white',
        },
      },
    },
  }),
);

export interface DateAndTimePickersProps {
  onChange: (date: Date) => void;
  value: Date;
}

export const DateAndTimePicker = ({ onChange, value }: DateAndTimePickersProps) => {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label="held At"
        type="datetime-local"
        className={classes.textField}
        defaultValue={value?.toISOString().slice(0, 16)}
        InputLabelProps={{
          shrink: true,
        }}
        value={value?.toISOString().slice(0, 16)}
        onChange={(e) => onChange(new Date(e.target.value))}
      />
    </form>
  );
}
