import { createApiRef } from '@backstage/core-plugin-api';
import { Auth, AuthRequest, GetMeetingResponse,} from '@platt/plugin-platt-cognitive-minutes-common';

export const transcriptApiRef = createApiRef<TranscriptApi>({
  id: 'plugin.cognitive-minutes.transcript.service',
});

export interface TranscriptApi {
  /**
   * videoのblobを受け取り、transcriptを返す
   */
  getAuth(request: AuthRequest): Promise<Auth>;
  getMeeting(id:string): Promise<GetMeetingResponse>;
}