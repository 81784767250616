import React from 'react';
import {
  Link,
  Table,
  TableColumn,
} from '@backstage/core-components';
import { EntityListProvider, useEntity } from '@backstage/plugin-catalog-react';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { Box, Button, Typography } from '@material-ui/core';
import { MeetingUploadDialog } from './UploadDialog';
import { useUploadModal } from '../../hooks/useUploadModal';
import { useMeeting } from '../../hooks/useMeetings';
import { MeetingEntityV1alpha1, MeetingRepoEntityV1alpha1 } from '@platt/plugin-platt-cognitive-minutes-common';

export interface MeetingTableProps {
  columns?: TableColumn<CatalogTableRow>[];
}

interface MeetingCatalogRow {
  title: string | JSX.Element;
  state: string;
  owner: string;
  updateAt: string;
}

const columns: TableColumn<MeetingCatalogRow>[] = [
  { title: 'Title', field: 'title' },
  { title: 'State', field: 'state' },
  { title: 'Owner', field: 'owner' },
  { title: 'UpdateAt', field: 'updateAt', defaultSort: 'desc', customSort: (a, b) => new Date(a.updateAt).getTime() - new Date(b.updateAt).getTime()}
];

export interface MeetingTableProps {
  meetings: MeetingEntityV1alpha1[];
}

export const MeetingTable = React.memo(({ meetings }: MeetingTableProps) => {
  const data = meetings.map((m) => {
    return {
      title: m.spec.lifecycle === 'summerize:success' ?
        <Link style={{ fontWeight: 'bold' }} to={m.spec.path}>{m.spec.title}</Link> :
        <Typography variant='body2'>{m.spec.title}</Typography>,
      state: m.spec.lifecycle,
      owner: m.spec.owner,
      // convert utc to local time
      updateAt: m.spec.updateAt ? new Date(m.spec.updateAt!).toLocaleString() : '',
    } as MeetingCatalogRow;
  });
  return (
    <Table
      columns={columns}
      options={
        {
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
          sorting: true,
          keepSortDirectionOnColumnSwitch: true,
        }
      }
      data={data}
      title='Meetings'
    />
  );
});

export const MeetingCatalogPage = () => {
  const { state, toggleModal } = useUploadModal();
  const { isLoaded, meetings, getMeetings } = useMeeting();
  const currentRepo = useEntity<MeetingRepoEntityV1alpha1>();
  if (!isLoaded) {
    getMeetings(currentRepo.entity);
  }
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={toggleModal} >
          Upload
        </Button>
      </Box>
      <EntityListProvider>
        <MeetingTable meetings={meetings} />
        <MeetingUploadDialog
          open={state.open}
          hidden={state.hidden}
          toggleModal={toggleModal}
          meetings={meetings} />
      </EntityListProvider>
    </Box>
  );
}


