import React from 'react'
import { Accept, DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone'
import { Typography, makeStyles, Box } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// スタイルの型定義
interface StyleProps {
  root: CSSProperties;
  active: CSSProperties;
  accept: CSSProperties;
  reject: CSSProperties;
}


// スタイルの作成
const useStyles = makeStyles<StyleProps>((_)=> ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '100%',
    height: '100%'
  },
  active: {
    borderColor: '#2196f3'
  },
  accept: {
    borderColor: '#00e676'
  },
  reject: {
    borderColor: '#ff1744'
  }
}))

export interface MediaDropZoneProps {
  onDrop: (acceptedFiles: File[]) => void;
}


// ドロップゾーンのコンポーネント
export const MediaDropZone = ({ onDrop }: MediaDropZoneProps) => {
  const classes = useStyles()
  // 選択された画像を処理
  // ドロップゾーンのスタイルを決定する
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { 'text/plain': ['.txt'], 'video/mpeg': ['.mp4'], 'audio/mpeg': ['.mp3'] } as Accept,
  });
  // ドロップゾーンのスタイルを決定する
  let style = classes.root
  if (isDragActive) {
    style += ` ${classes.active}`
  }
  if (isDragAccept) {
    style += ` ${classes.accept}`
  }
  if (isDragReject) {
    style += ` ${classes.reject}`
  }

  // ドロップゾーンのルート要素のプロパティ
  const rootProps = getRootProps() as DropzoneRootProps

  // ドロップゾーンの入力要素のプロパティ
  const inputProps = getInputProps() as DropzoneInputProps

  return (
    <Box className={style} {...rootProps}>
      <input {...inputProps} />
      <Typography variant="h6">click here ...</Typography>
      <br />
      <CloudUploadIcon fontSize='large' />
      <br />
      <Typography variant="h6">or drag and drop</Typography>
    </Box >
  );
}
