import { AskMeetingResponse, AskRequest, AskResponse } from '@platt/plugin-platt-cognitive-faq-common';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { CognitiveFaqApi } from './CognitiveFaqApi';

export interface CognitiveFaqClientOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
}

export class CognitiveFaqClient implements CognitiveFaqApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: CognitiveFaqClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async ask(req: AskRequest): Promise<AskResponse> {
    const abortController = new AbortController();
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-faq')}/`;
      const response = await this.fetchApi.fetch(`${baseUrl}ask`, {
        method: 'POST',
        body: JSON.stringify(req),
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'accept-encoding': 'gzip, deflate, br',
          'connection': 'keep-alive'
        } as HeadersInit,
        signal: abortController.signal
      });
      if (!response.body) {
        throw new Error('response body null');
      }
      if (!response.ok) {
        throw new Error('response not ok');
      }
      const result = await response.json();
      return result as AskResponse;
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }
  async askMeeting(req: AskRequest): Promise<AskMeetingResponse> {
    const abortController = new AbortController();
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-faq')}/`;
      const response = await this.fetchApi.fetch(`${baseUrl}ask/meetings`, {
        method: 'POST',
        body: JSON.stringify(req),
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'accept-encoding': 'gzip, deflate, br',
          'connection': 'keep-alive'
        } as HeadersInit,
        signal: abortController.signal
      });
      if (!response.body) {
        throw new Error('response body null');
      }
      if (!response.ok) {
        throw new Error('response not ok');
      }
      const result = await response.json();
      return result as AskMeetingResponse;
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }

  async askTemplate(req: AskRequest): Promise<AskMeetingResponse> {
    const abortController = new AbortController();
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-faq')}/`;
      const response = await this.fetchApi.fetch(`${baseUrl}ask/templates`, {
        method: 'POST',
        body: JSON.stringify(req),
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'accept-encoding': 'gzip, deflate, br',
          'connection': 'keep-alive'
        } as HeadersInit,
        signal: abortController.signal
      });
      if (!response.body) {
        throw new Error('response body null');
      }
      if (!response.ok) {
        throw new Error('response not ok');
      }
      const result = await response.json();
      return result as AskMeetingResponse;
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }

  async content(query: string): Promise<void> {
    try {
      const baseUrl = `${await this.discoveryApi.getBaseUrl('platt-cognitive-faq')}/content/${query}`;
      const response = await this.fetchApi.fetch(baseUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'accept': "*/*",
          'accept-encoding': 'gzip, deflate, br',
          'connection': 'keep-alive'
        } as HeadersInit
      });
      if (!response.ok) {
        throw new Error('response not ok');
      }
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob], { type: "application/octet-stream" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", query);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Connection timed out.');
      }
      throw error;
    }
  }
}