import React from 'react'
import { Grid } from '@material-ui/core'
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityPage as DefaultEntityPage } from '@platt-internal/backstage-plugin-bootstrap-react';
import { MeetingCatalogPage } from '@platt/plugin-platt-cognitive-minutes';

export const meetingRepoPage = () => (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Meetings">
      <Grid container spacing={3} alignItems="stretch">
        {DefaultEntityPage.defaultEntityWarningContent}
        <Grid item xs>
          <MeetingCatalogPage />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
