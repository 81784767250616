import { CompoundEntityRef } from '@backstage/catalog-model';
import { createApiRef } from '@backstage/core-plugin-api';
import {
  QueryIssueGraphResponse,
  RepositoryIsuesResponse
} from '@platt/plugin-repository-catalog-common';

export const repositoryApiRef = createApiRef<RepositoryApi>({
  id: 'platt.repository-catalog.service',
});

export type QueryPageinationOptions = {
  page: number,
  pageSize: number
};

export interface RepositoryApi {
  getRepositoryIssues(
    entityRef: CompoundEntityRef,
    options?: QueryPageinationOptions
  ): Promise<RepositoryIsuesResponse>;
  retrieveIssuesFromRepository(entityRef: CompoundEntityRef): Promise<void>;
  getIssueGraphById(id: string): Promise<QueryIssueGraphResponse>;
}
